import React from "react"
import HomeTile from "../components/HomeTile"
import styled from 'styled-components';
import abacus from '../assets/images/abacus.png';
import woman_veil from '../assets/images/woman_veil.png';
import chess from '../assets/images/chess.png';

const HomePageStyles = styled.div`
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* justify-content: space-around; */
  align-items: center;
`;

export default function HomePage() {
  return <HomePageStyles>
    <HomeTile
      description='Opinions and insights on software development'
      imgSrc={abacus}
      linkTo='/blog'
      title='Engineering Blog'
    />
    <HomeTile
      description='Life, skills, and experiences as a software engineer'
      imgSrc={woman_veil}
      linkTo='/portfolio'
      title='About me'
    />
    <HomeTile
      description='Side projects I am currently working on'
      imgSrc={chess}
      linkTo='/projects'
      title='Featured Projects'
    />
  </HomePageStyles>

}
