import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';




const HomeTileStyles = styled.div`
  background-color: var(--ivory);
  color: var(--graphite);
  height: 450px;
  width: 250px;
  margin: 8px;
  padding: 20px;
  position: relative;
  font-weight: bold;
  z-index: 1;
  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
    text-decoration: none; /* no underline */
  }
  :after, :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid rgba(68,69,68, 0.4);
    transition: transform 0.3s;
    z-index: -1;
  }
  :after {
    transform: translate(3px, 3px);
  }
  :before {
    transform: translate(-3px, -3px);
  }
  :hover:after, :hover:before {
    transform: translate(0);
    border-color: rgba(68,69,68, 1);
  }
  img {
    width: 100%;
  }
`


export default function HomeTile({ description, imgSrc, linkTo, title }) {
  return (
    <HomeTileStyles>
      <Link to={linkTo}>
        <h3>{ title }</h3>
        <img src={imgSrc} alt=""/>
        <p>{ description }</p>
      </Link>

    </HomeTileStyles>
  )
}